import React from "react"
import { ThemeProvider } from "styled-components"

import { MobileProvider } from "./src/providers/mobile/mobile.provider"
import { ModalProvider } from "./src/providers/modal/modal.provider"
import { AlertBannerProvider } from "./src/providers/alert-banner/alert-banner.provider"
import { PinnedCTAProvider } from "./src/providers/pinnedCTA/pinnedCTA.provider"
import { ShortPathProvider } from "./src/providers/short-path/short-path.provider"

import Modal from "./src/components/modal/modal.component"

import "./src/layouts/page/base.css"
import theme from "./src/themes/theme"
import "@fontsource/barlow" // Defaults to weight 400.
import "@fontsource/barlow/300.css" // Weight 300.
import "@fontsource/barlow/600.css" // Weight 600.
import "@fontsource/barlow/700.css" // Weight 700.
import "@fontsource/barlow/600-italic.css" // Weight 600 // Font-Style Italic
import { BlogSidebarProvider } from "./src/providers/blog-sidebar/blog-sidebar.provide"

require("prismjs/themes/prism-tomorrow.css")
require("prismjs/plugins/line-numbers/prism-line-numbers.css")

export const onClientEntry = async () => {
  if (!(`IntersectionObserver` in window)) {
    await import(`intersection-observer`)
  }
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <React.Fragment>
      {element}
      <Modal />
    </React.Fragment>
  )
}

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <ModalProvider>
        <MobileProvider>
          <PinnedCTAProvider>
            <ShortPathProvider>
              <BlogSidebarProvider>
                <AlertBannerProvider>{element}</AlertBannerProvider>
              </BlogSidebarProvider>
            </ShortPathProvider>
          </PinnedCTAProvider>
        </MobileProvider>
      </ModalProvider>
    </ThemeProvider>
  )
}
