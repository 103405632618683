import React, { useState, useEffect, createContext } from "react"

const MobileContext = createContext({
  isMobile: false,
  isMenuHidden: false,
  showBurger: false,
  toggleMenu: () => {},
  closeMenu: () => {},
})

export const MobileProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false)
  const [isStatic, setIsStatic] = useState(false)
  const [isMenuHidden, setMenuHidden] = useState(false)
  const [showBurger, setShowBurger] = useState(true)
  const [showDropDown, setShowDropDown] = useState(false)

  const toggleMenu = () => setMenuHidden(!isMenuHidden)
  const closeMenu = () => setMenuHidden(false)

  const onWindowSizeChange = () => {
    window.innerWidth > 780 ? setIsMobile(false) : setIsMobile(true)
    window.innerWidth > 1000 ? setIsStatic(false) : setIsStatic(true)
    window.innerWidth > 1200 ? setShowBurger(false) : setShowBurger(true)
    window.innerWidth > 1185 ? setShowDropDown(false) : setShowDropDown(true)
  }

  useEffect(() => {
    onWindowSizeChange()

    window.addEventListener("resize", onWindowSizeChange)
    return () => {
      window.removeEventListener("resize", onWindowSizeChange)
    }
  }, [])

  return (
    <MobileContext.Provider
      value={{
        isMobile,
        toggleMenu,
        closeMenu,
        isMenuHidden,
        showBurger,
        isStatic,
        showDropDown,
      }}
    >
      {children}
    </MobileContext.Provider>
  )
}

export default MobileContext
